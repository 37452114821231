import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
import Seo from "../templates/seo"
import Post from "../components/organisms/post"
import Sidebar from "../components/organisms/sidebar"
import { l_page_container, l_page_content } from "../components/atoms/__layout"


const AboutPage: React.FC<PageProps<Queries.AboutPageQuery>> = ({ data }) => {
  const post = data.markdownRemark
  const name = data.site?.siteMetadata?.author?.name
  const description = data?.site?.siteMetadata?.description
  const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title="About" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <Post post={post} />
        </section>
        {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />}
      </div>
    </Layout>
  )
}

export default AboutPage


export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { regex: "//about/" } }) {
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
      }
      html
      tableOfContents
    }
  }
`
