import React from "react"
import { css } from "@emotion/react"

import Card from "../../components/atoms/card"
import Tags from "../molecules/tags"
import Date from "../molecules/date"
import styled from "@emotion/styled"

const Post = ({ post }) => {
  return (
    <Card>
      <div css={o_wapper}>
        <h1 itemProp="headline" css={o_title}>
          {post?.frontmatter?.title}
        </h1>
        <Date date={post?.frontmatter?.date} />
        <Tags tags={post?.frontmatter?.tags} />
        <div css={o_page_content}>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </div>
      </div>
    </Card>
  )
}

export default Post

const o_page_content = css`
  h2 {
    padding: 0.1em 0.5em;/*文字の上下 左右の余白*/
    color: #494949;/*文字色*/
    /* background: #fcfdff;背景色 */
    border-left: solid 5px #6A6B6B;/*左線*/
    border-bottom: solid 2px #d7d7d7;/*下線*/
  }
  h3 {
    padding: 0.25em 0.5em;
    color: #494949;
    background: transparent;
    border-left: solid 5px #6A6B6B;
  }
  h4 {
    display: inline-block;
    border-bottom: solid 3px #CFD1D1;
    margin: 0;
  }
`

const o_wapper = css`
  padding: 30px;
  @media (max-width: 960px) {
    padding: 15px;
  }
`

const o_title = css`
  margin: 0;
`
